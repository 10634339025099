import { VueReCaptcha } from "vue-recaptcha-v3";

export default defineNuxtPlugin((nuxtApp) => {
  if (useRuntimeConfig().public.recaptcha.siteKey) {
    nuxtApp.vueApp.use(VueReCaptcha, {
      siteKey: useRuntimeConfig().public.recaptcha.siteKey,
      loaderOptions: {
        autoHideBadge: true,
        explicitRenderParameters: {
          // badge: 'bottomleft',
        },
      },
    });
  }
});
